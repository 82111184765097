/* eslint-disable max-lines */
import React, {forwardRef, MutableRefObject, useCallback, useMemo} from 'react'
import {ELineChartProps} from "components/charts/line/LineChart.types"
import EChartContainer from "components/charts/Chart.Container"
import ChartBase, {BaseChartRef} from "components/charts/Chart.Base"
import {DISTANCE_BETWEEN_CHART_TOP_AND_LEGEND} from "components/charts/Chart.constants"
import {useFillBlanks, useGetSeriesLabel, useScatterChartRawParsedMetrics, useSumOfTreeSeries} from "components/charts/Chart.utils"
import {GenericEChartProps} from 'components/charts/line/LineChart'
import {
  scatterChartTooltip,
  useComputeMaxWithOffset,
  useComputeMinWithOffset,
  useDatas,
  useFormatValue,
  useIsThirdMetricWithZeroValue,
} from "components/charts/scatter/ScatterChart.utils"
import Language from "language"
import {ArrowsExpandIcon} from "@heroicons/react/outline"
import {IconContainer} from "components/common/IconContainer"
import styled from "styled-components"
import {Empty} from "antd"

export interface Hoverdata {
  yAxisIndex?: number
  borderColor?: string
  color?: string
  componentIndex?: number
  componentSubType?: string
  componentType?: string
  data?: number
  dataIndex: number
  dataType?: string
  dimensionNames?: (string | undefined)[]
  encode?: {
    x: number[]
    y: number[]
  }
  name?: string
  seriesId?: string
  seriesIndex?: number
  seriesName?: string
  seriesType?: string
  type?: string
  value?: number
}

export interface Axis {
  type: "category" | "value"
  data?: (string | number | {
    value?: string | number | undefined
  })[]
  axisLabel: {
    formatter?: string | ((label: string, i: number) => string | undefined)
    rotate?: number
  }
  axisLine: {
    lineStyle: {
      color: string
    }
  }
  min?: number
  max?: number
}

// Implement this workaround to have legend for each data point https://stackoverflow.com/questions/52771079/echarts-display-corresponding-legend-for-each-bar
const EScatterChartWithLegend = forwardRef<any, ELineChartProps>(function EScatterChartWithLegend(props, ref) {
  const {extraConf, effectiveConf} = props.rawChartData.meta

  const [firstLimit, secondLimit] = useMemo(() => extraConf.limits && extraConf.limits?.length > 0 ? extraConf.limits : [], [extraConf.limits])

  const slicers = useMemo(() => effectiveConf.slicers, [effectiveConf.slicers])
  const metrics = useMemo(() => effectiveConf.metrics, [effectiveConf.metrics])
  const hasSlicer = useMemo(() => slicers.length > 0, [slicers])
  const firstSlicerIndex = 0
  const indexOfSizeMetric = 2

  const canDisplayData = useMemo(() => metrics.length > 1, [metrics.length])

  const parsedDataWithBlanksFilled = useFillBlanks(props.rawChartData.parsedData, false, hasSlicer, props.rawChartData.expectedDates)
  const sumOfValues = useSumOfTreeSeries(parsedDataWithBlanksFilled, metrics.length >= 2 ? [0, 1] : [], (value1: number, value2: number) => value1 * value2)

  const parsedMetrics = useScatterChartRawParsedMetrics(parsedDataWithBlanksFilled, slicers, firstLimit, secondLimit, firstSlicerIndex, sumOfValues)
  const metricFormats = useMemo(() => parsedMetrics.map(m => m.format), [parsedMetrics])
  const xAxisMetricFormat = useMemo(() => metricFormats.length > 0 ? metricFormats[0] : undefined, [metricFormats])
  const yAxisMetricFormat = useMemo(() => metricFormats.length > 1 ? metricFormats[1] : undefined, [metricFormats])

  const hasTitle = useMemo(() => parsedMetrics.length > 2, [parsedMetrics.length])

  const datas = useDatas(parsedMetrics)

  const formatValue = useFormatValue(datas)
  const initialColors = useMemo(() => parsedMetrics.length > 1 ? parsedMetrics[1].getColorScaleAt(1) : [], [parsedMetrics])
  const getSeriesLabel = useGetSeriesLabel(indexOfSizeMetric)

  const renderedSeries = useMemo(() => {
    return datas.map((d, di) =>
      ({
        label: getSeriesLabel(metricFormats[indexOfSizeMetric], undefined, props.selection?.displayLabels),
        name: d.label,
        data: d.data,
        type: 'scatter',
        symbolSize: (daa: any) => {
          return metrics.length === 2 ? daa[2] : formatValue(daa[2])
        },
        color: initialColors[di],
      }))
  }, [datas, formatValue, getSeriesLabel, initialColors, metricFormats, metrics.length, props.selection?.displayLabels])

  const mouseoverCallBack = useCallback((dat?: Hoverdata) => {
    if (dat) {
      const echarts = (ref as MutableRefObject<BaseChartRef>)?.current.getEchartsInstance()
      echarts?.setOption({
        tooltip: scatterChartTooltip(metricFormats, effectiveConf),
      })
    }
  }, [effectiveConf, metricFormats, ref])

  const mouseoutCallback = useCallback(() => {
    const echarts = (ref as MutableRefObject<BaseChartRef>)?.current.getEchartsInstance()
    echarts?.setOption({
      series: renderedSeries,
    })
  }, [ref, renderedSeries])

  const computeMaxWithOffset = useComputeMaxWithOffset(datas, props.dimensions)
  const computeMinWithOffset = useComputeMinWithOffset(datas, props.dimensions)

  const options = useMemo(() => {
    return ({
      xAxis: {
        data: datas.flatMap(d => d.data.flatMap(dd => dd[0])),
        splitLine: {
          lineStyle: {
            type: 'dashed',
          },
        },
        min: computeMinWithOffset(0),
        max: computeMaxWithOffset(0),
        type: "value",
      },
      yAxis: {
        data: datas.flatMap(d => d.data.flatMap(dd => dd[1])),
        splitLine: {
          lineStyle: {
            type: 'dashed',
          },
        },
        min: computeMinWithOffset(1),
        max: computeMaxWithOffset(1),
        type: "value",
        scale: true,
      },
      grid: {
        top: props.selection?.displayLabels ? DISTANCE_BETWEEN_CHART_TOP_AND_LEGEND : undefined,
        containLabel: true,
      },
      series: renderedSeries,
    })
  }, [computeMaxWithOffset, computeMinWithOffset, datas, props.selection?.displayLabels, renderedSeries])

  const isThirdMetricWithZeroValue = useIsThirdMetricWithZeroValue(datas)

  return canDisplayData ? <ChartBase ref={ref}
                                     option={options}
                                     dimensions={props.dimensions}
                                     includeLegend={slicers.length > 1}
                                     xAxisName={parsedMetrics[0]?.metric.metricAlias}
                                     yAxisName={parsedMetrics[1]?.metric.metricAlias}
                                     xAxisFormats={xAxisMetricFormat ? [xAxisMetricFormat] : undefined}
                                     yAxisFormats={yAxisMetricFormat ? [yAxisMetricFormat] : undefined}
                                     events={{
                                       mouseover: mouseoverCallBack,
                                       mouseout: mouseoutCallback,
                                     }}
                                     warning={isThirdMetricWithZeroValue ? Language.get("configuration-warning-information-hidden") : undefined}
                                     footer={hasTitle ? <MetricLegend>
                                       <IconContainer size={16}><ArrowsExpandIcon/></IconContainer>
                                       <MetricLegendContainer>{parsedMetrics[2].metric.metricAlias}</MetricLegendContainer>
                                     </MetricLegend> : undefined}/> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                                                                             description={Language.get("not-enough-metrics-to-display-data")}
                                                                             style={{
      ...props.dimensions,
      margin: 0,
      padding: 32,
    }}/>
})

const ScatterChart = forwardRef<any, GenericEChartProps>(function ScatterChart(props, ref) {
  return <EChartContainer ref={ref} {...props} chart={EScatterChartWithLegend} rawChartData={props.chartData}/>
})

export default ScatterChart
const MetricLegendContainer = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`
const MetricLegend = styled.div`
    display: flex;
    color: #1B1B1D;
    font-weight: 400;
    font-size: 10px;
    align-items: center;
    width: 100%;
    gap: 5px;
`